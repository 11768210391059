import React from 'react'
import './App.css';


const ENTREES=[
  {
    des:"Cannellonis farcis aux écrevisses sur bisque de homard",
    price:"17.00"
  },
  {
    des:"Vitello tonato",
    price:"16.00"
  },
  {
    des:"Cuisses de grenouilles à l'ail",
    price:"15.00"
  },
  {
    des:"Terrine de foie gras d'oie, pain brioché, confiture de figues",
    price:"18.00"
  },
  {
    des:"Les croquettes aux crevettes grises",
    price:"18.00"
  }
]

const PLATS=[
  {
    des:"Dos de cabillaud, tombée de scaroles et tomates cerises rôties",
    price:"28.00"
  },
  {
    des:"Filet de porc Ibérique, crème d'haricots coco et salsa verde",
    price:"28.00"
  },
  {
    des:"Pigeonneau d'Anjou, pommes amandine, garniture d'automne",
    price:"35.00"
  },
  {
    des:"Filet pur de boeuf Irlandais, sauce poivres concassés, pommes croquettes",
    price:"33.00"
  },
  {
    des:"Carré d'agneau rôti en croûte d'herbes, jus à la moutarde",
    price:"30.00"
  }

]

const DESSERTS=[
  {
    des:"Meringue aux fruits rouges, glace vanille",
    price:"9.00"
  },
  {
    des:"Moelleux au chocolat glace vanille",
    price:"9.00"
  },
  {
    des:"Crumble pommes et cerises, glace vanille",
    price:"9.00"
  },
  {
    des:"Tartelette citron meringué",
    price:"9.00"
  },
  {
    des:"Dame blanche",
    price:"10.00"
  }
]




const HorizontalLine = ({ width, color }) => {
  const lineStyle = {
    width: width || '100%', 
    height: '1px',      
    backgroundColor: color || 'white', 
    margin: '10px 0',      
  };

  return <div style={lineStyle}></div>;
};

const CarteComponent=(des, price)=>{

  return(
    <div style={{marginTop:-10,justifyContent:"space-between", flexDirection:"row", display:"flex", width:"100%"}}>
      <p style={{maxWidth:"80%"}}>{des}</p>
      <p style={{fontSize:18}}>{price}€</p>

    </div>
  )
}
function Carte() {
  return (
    <div className="origin">
    <div className="gallery-wrapper">
    
    <title>Restaurant De Bouche a Oreille Jurbise - Carte</title>
    <div className="gallery" style={{justifyContent:'center', display:"flex", color:"white", fontFamily:"-moz-initial", fontSize:20, flexDirection:"column", alignItems:"center", width:"100%"}}>
   



     {/* MENU */}
     <p style={{ fontFamily:'fantasy',fontSize:22, textAlign:"center"}}>Menu<br/>45€</p>
     
     <div style={{marginTop:-20, fontFamily:"-moz-initial", fontSize:20, alignItems:'center', justifyContent:"center", display:'flex', flexDirection:"column", maxWidth:"80%", textAlign:"center"}}>
     <p>Mises en bouche</p>
     <HorizontalLine width="20%"  />
     <p>Viltello tonato</p>

    <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Cannellonis farcis aux écrevisses sur bisque de homard</p>
      {/*
     <HorizontalLine width="20%"  />

     <p>Foie gras d'oie poêlé aux raisins</p>
     
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Dos de cabillaud, pommes purée de brocolis beurre aux tomates cerises</p>*/}

     <HorizontalLine width="20%"  />
     <p>Filet de porc Ibérique, crème d'haricots coco et salsa verde</p>
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Dos de cabillaud, tombée de scaroles, tomates cerises rôties</p>

     <HorizontalLine width="20%"  />
     <p>Dessert OU Fromages</p>

     </div>





      {/* Carte */}
     <p style={{fontFamily:'fantasy',fontSize:22}}>Carte</p>

     <p style={{fontFamily:"fantasy", fontSize:18, marginTop:20, alignSelf:"flex-start", marginLeft:"10%"}}>Entrées</p>
     <div style={{fontFamily:"-moz-initial",fontSize:20, display:'flex', flexDirection:"column", width:"80%", marginTop:-20}}>

     {ENTREES.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>



     <p style={{ fontFamily:"fantasy", fontSize:18, alignSelf:"flex-start", marginLeft:"10%"}}>Plats</p>
     <div style={{fontFamily:"-moz-initial", fontSize:20, display:'flex', flexDirection:"column", width:"80%",marginTop:-20}}>

     {PLATS.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>

     <p style={{ fontFamily:"fantasy", fontSize:18, alignSelf:"flex-start", marginLeft:"10%"}}>Desserts</p>
     <div style={{fontFamily:"-moz-initial", fontSize:20, display:'flex', flexDirection:"column", width:"80%",marginTop:-20}}>

     {DESSERTS.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>

    </div>
    </div>
    </div>
  );
}

export default Carte
